<template>
  <el-container>
    <Header pageName="版本管理"></Header>
    <el-main>
      <el-button style="margin-bottom: 10px" type="primary" size="small" @click="addVersion">升级版本</el-button>
      <el-tabs v-model="tabsActive" type="card" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in pageConfig" :key="index" :label="item.name" :name="item.status"></el-tab-pane>
      </el-tabs>
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="version" label="版本号" align="center"></el-table-column>
        <el-table-column prop="template_id" label="模板ID" align="center"></el-table-column>
        <el-table-column prop="case_name" label="版本" align="center"></el-table-column>
        <el-table-column prop="content" label="更新内容" align="center"></el-table-column>
        <el-table-column prop="createtime" label="更新日期" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.createtime) }}</template>
        </el-table-column>
        <el-table-column prop="money" label="操作" align="center">
          <template v-slot="{ row }">
            <el-button @click="del(row)" size="small" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
      <el-drawer title="升级版本" :visible.sync="addVersionFlag" direction="rtl">
        <el-form label-width="120px" class="drawer-form">
          <el-form-item label="小程序类别：">
            <el-select size="small" v-model="chooseAddType" placeholder="请选择">
              <el-option v-for="item in pageConfig" :key="item.status" :label="item.name" :value="item.status"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应用类别：">
            <el-select size="small" v-model="addVersionObj.case_id" placeholder="请选择">
              <el-option v-for="item in caseList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板ID：">
            <el-input size="small" v-model="addVersionObj.template_id" placeholder="请输入模板ID"></el-input>
          </el-form-item>
          <el-form-item label="最新版本：">
            <el-input size="small" v-model="addVersionObj.version" placeholder="请输入最新版本"></el-input>
          </el-form-item>
          <el-form-item label="版本内容：">
            <el-input type="textarea" v-model="addVersionObj.content" rows="6" show-word-limit></el-input>
          </el-form-item>
          <div class="btnBox">
            <el-button size="small" @click="addVersionFlag = false">取消</el-button>
            <el-button size="small" type="primary" @click="sureAddVersion">确定</el-button>
          </div>
        </el-form>
      </el-drawer>
    </el-main>
  </el-container>
</template>

<script>
import Paging from '@/components/paging';
import Header from './components/header';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
    Header,
  },
  data() {
    return {
      getDateformat,
      page: 1,
      rows: 10,
      total: 0,
      list: [],
      caseList: [],
      tabsActive: 1,
      activeConfig: {},
      pageConfig: [
        { status: 1, name: '微信小程序', listPath: this.$api.admin.versionManagement.wxList, addPath: this.$api.admin.versionManagement.wxAdd, delPath: this.$api.admin.versionManagement.wxDel },
        { status: 2, name: '支付宝小程序', listPath: this.$api.admin.versionManagement.aliList, addPath: this.$api.admin.versionManagement.aliAdd, delPath: this.$api.admin.versionManagement.aliDel },
        {
          status: 3,
          name: '百度小程序',
          listPath: this.$api.admin.versionManagement.baiduList,
          addPath: this.$api.admin.versionManagement.baiduAdd,
          delPath: this.$api.admin.versionManagement.baiduDel,
        },
      ],
      addVersionFlag: false,
      addVersionObj: {},
      chooseAddType: 1,
    };
  },
  created() {
    this.activeConfig = this.pageConfig[0];
    this.getList();
    this.getCase();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    addVersion() {
      this.addVersionObj = {
        version: '',
        template_id: '',
        content: '',
        case_id: this.caseList[0].id,
      };
      this.addVersionFlag = true;
    },
    sureAddVersion() {
      if (this.addVersionObj.version === '') {
        this.$message.warning('请填写最新版本');
        return;
      }
      if (this.addVersionObj.template_id === '') {
        this.$message.warning('请填写模板ID');
        return;
      }
      if (this.addVersionObj.content === '') {
        this.$message.warning('请填写版本信息');
        return;
      }
      let path = this.pageConfig.find(item => item.status === this.chooseAddType).addPath;
      this.$axios.post(path, this.addVersionObj).then(res => {
        if (res.code === 0) {
          this.$message.success('添加成功');
          this.page = 1;
          this.addVersionFlag = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del(row) {
      this.$confirm('此操作将永久删除该版本信息, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(res => {
          return this.$axios.post(this.activeConfig.delPath, {
            id: row.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleClick() {
      this.activeConfig = this.pageConfig.find(item => item.status === this.tabsActive);
      this.page = 1;
      this.getList();
    },
    getList() {
      this.$axios
        .post(this.activeConfig.listPath, {
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total = res.result.total;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getCase() {
      this.$axios.post(this.$api.admin.caseList, { pagesize: 10000 }).then(res => {
        if (res.code == 0) {
          this.caseList = res.result.list;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      margin-top: 10px;
    }
    .drawer-form {
      padding-right: 50px;
    }
    .btnBox {
      padding-right: 50px;
      margin-top: 100px;
      display: flex;
      justify-content: flex-end;
    }
    .rowClass {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
